import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { PullRequestFilter } from '@core/data/model/dto/pull-request-filter';
import { PullRequestStatusType } from '@core/data/type/pull-request-status.type';
import { PullRequest } from '@core/data/model/pull-request';
import { tap } from 'rxjs/operators';
import { Contributor } from '@core/data/model/contributor';
import { Incident } from '@core/data/model/incident';
import { IncidentFilter } from '@core/data/model/dto/incident-filter';
import { IncidentStatusType } from '@core/data/type/incident-status.type';
import { IncidentPriorityType } from '@core/data/type/incident-priority.type';
import { CurrencyPreference } from '@core/data/model/currency-preference';

@Injectable()
export class FinanceService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('finance-service', httpClient);
  }

  findCurrencyPreference(): Observable<CurrencyPreference> {
    return this.get<CurrencyPreference>('/currency-preferences');
  }
}
